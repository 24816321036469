import $ from 'jquery'

import { CALL_API } from 'middleware/api'
export const ORDER_CLICK = 'ORDER_CLICK'
export const ORDER_MAP_CLICK = 'ORDER_MAP_CLICK'
export const ORDER_DETAIL_CLOSE = 'ORDER_DETAIL_CLOSE'
export const COURIER_MAP_CLICK = 'COURIER_MAP_CLICK'
export const ORDERS_PENDING_REQUEST = 'ORDERS_PENDING_REQUEST'
export const ORDERS_PENDING_SUCCESS = 'ORDERS_PENDING_SUCCESS'
export const ORDERS_PENDING_FAILURE = 'ORDERS_PENDING_FAILURE'
export const UPDATE_PENDING_STATUS_REQUEST = 'UPDATE_PENDING_STATUS_REQUEST'
export const UPDATE_PENDING_STATUS_SUCCESS = 'UPDATE_PENDING_STATUS_SUCCESS'
export const UPDATE_PENDING_STATUS_FAILURE = 'UPDATE_PENDING_STATUS_FAILURE'
export const ORDERS_ACTIVE_REQUEST = 'ORDERS_ACTIVE_REQUEST'
export const ORDERS_ACTIVE_SUCCESS = 'ORDERS_ACTIVE_SUCCESS'
export const ORDERS_ACTIVE_FAILURE = 'ORDERS_ACTIVE_FAILURE'
export const ORDERS_HISTORICAL_REQUEST = 'ORDERS_HISTORICAL_REQUEST'
export const ORDERS_HISTORICAL_SUCCESS = 'ORDERS_HISTORICAL_SUCCESS'
export const ORDERS_HISTORICAL_FAILURE = 'ORDERS_HISTORICAL_FAILURE'
export const LIST_INVOICES_REQUEST = 'LIST_INVOICES_REQUEST'
export const LIST_INVOICES_SUCCESS = 'LIST_INVOICES_SUCCESS'
export const LIST_INVOICES_FAILURE = 'LIST_INVOICES_FAILURE'
export const LIST_PURSE_REQUEST = 'LIST_PURSE_REQUEST'
export const LIST_PURSE_SUCCESS = 'LIST_PURSE_SUCCESS'
export const LIST_PURSE_FAILURE = 'LIST_PURSE_FAILURE'
export const LIST_COUPON_REQUEST = 'LIST_COUPON_REQUEST'
export const LIST_COUPON_SUCCESS = 'LIST_COUPON_SUCCESS'
export const LIST_COUPON_FAILURE = 'LIST_COUPON_FAILURE'
export const UPDATE_ORDER_HISTORICAL_FILTERS = 'UPDATE_ORDER_HISTORICAL_FILTERS'
export const UPDATE_ORDER_ACTIVE_FILTERS = 'UPDATE_ORDER_ACTIVE_FILTERS'
export const UPDATE_ORDER_PENDING_FILTERS = 'UPDATE_ORDER_PENDING_FILTERS'
export const UPDATE_INVOICE_FILTERS = 'UPDATE_INVOICE_FILTERS'
export const LIST_DRAFTS_REQUEST = 'LIST_DRAFTS_REQUEST'
export const LIST_DRAFTS_SUCCESS = 'LIST_DRAFTS_SUCCESS'
export const LIST_DRAFTS_FAILURE = 'LIST_DRAFTS_FAILURE'
export const SELECT_ORDER = 'SELECT_ORDER'
export const DELETE_DRAFT_REQUEST = 'DELETE_DRAFT_REQUEST'
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS'
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE'
export const UPDATE_PURSE_FILTERS = 'UPDATE_PURSE_FILTERS'
export const PAYMENT_INVOICE_REQUEST = 'PAYMENT_INVOICE_REQUEST'
export const PAYMENT_INVOICE_FAILURE = 'PAYMENT_INVOICE_FAILURE'
export const PAYMENT_INVOICE_SUCCESS = 'PAYMENT_INVOICE_SUCCESS'
export const SHOW_MODAL_INFO = 'SHOW_MODAL_INFO'
export const CLOSE_MODAL_INFO = 'CLOSE_MODAL_INFO'
export const DUPLICATE_ORDER_REQUEST = 'DUPLICATE_ORDER_REQUEST'
export const DUPLICATE_ORDER_SUCCESS = 'DUPLICATE_ORDER_SUCCESS'
export const DUPLICATE_ORDER_FAILURE = 'DUPLICATE_ORDER_FAILURE'
export const DESTINATION_ORDER_LIST_REQUEST = 'DESTINATION_ORDER_LIST_REQUEST'
export const DESTINATION_ORDER_LIST_SUCCESS = 'DESTINATION_ORDER_LIST_SUCCESS'
export const DESTINATION_ORDER_LIST_FAILURE = 'DESTINATION_ORDER_LIST_FAILURE'

export function orderClick(order) {
  return function(dispatch) {
    const action = {
      type: ORDER_CLICK,
      order: order,
    }
    dispatch(action)
  }
}

export function orderMapClick(order) {
  return function(dispatch) {
    const action = {
      type: ORDER_MAP_CLICK,
      order: order,
    }
    dispatch(action)
  }
}

export function orderDetailClose() {
  return function(dispatch) {
    const action = {
      type: ORDER_DETAIL_CLOSE,
    }
    dispatch(action)
  }
}

export function courierMapClick(courier) {
  return function(dispatch) {
    const action = {
      type: COURIER_MAP_CLICK,
      courier: courier,
    }
    dispatch(action)
  }
}

export function pendingOrdersList(params = {}) {
  let url = `orders/?status=created`
  let qs = $.param(params, true)
  if (qs) url += '&' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        ORDERS_PENDING_REQUEST,
        ORDERS_PENDING_SUCCESS,
        ORDERS_PENDING_FAILURE,
      ],
    },
  }
}

export function updatePendingStatus(params = {}, data) {
  let url = `orders/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: url,
      extra: params,
      authenticated: true,
      data,
      types: [
        UPDATE_PENDING_STATUS_REQUEST,
        UPDATE_PENDING_STATUS_SUCCESS,
        UPDATE_PENDING_STATUS_FAILURE,
      ],
    },
  }
}

export function activeOrdersList(params = {}) {
  let url = `orders/active/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        ORDERS_ACTIVE_REQUEST,
        ORDERS_ACTIVE_SUCCESS,
        ORDERS_ACTIVE_FAILURE,
      ],
    },
  }
}

export function historicalOrdersList(params = {}) {
  let url = `orders/historical/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        ORDERS_HISTORICAL_REQUEST,
        ORDERS_HISTORICAL_SUCCESS,
        ORDERS_HISTORICAL_FAILURE,
      ],
    },
  }
}

export function destinationOrderList(params = {}) {
  let url = `destinations/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        DESTINATION_ORDER_LIST_REQUEST,
        DESTINATION_ORDER_LIST_SUCCESS,
        DESTINATION_ORDER_LIST_FAILURE,
      ],
    },
  }
}

export function listInvoices(params = {}) {
  let url = `invoices/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        LIST_INVOICES_REQUEST,
        LIST_INVOICES_SUCCESS,
        LIST_INVOICES_FAILURE,
      ],
    },
  }
}

export function listPurseMovements(params = {}) {
  let url = `purse/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [LIST_PURSE_REQUEST, LIST_PURSE_SUCCESS, LIST_PURSE_FAILURE],
    },
  }
}

export function listCoupons(params = {}) {
  let url = `coupon_registrations/`
  let qs
  if (params.page) qs = `page=${params.page}`
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [LIST_COUPON_REQUEST, LIST_COUPON_SUCCESS, LIST_COUPON_FAILURE],
    },
  }
}

export function listDrafts(params = {}) {
  let url = `drafts/`
  let qs
  if (params.page) qs = `page=${params.page}`
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [LIST_DRAFTS_REQUEST, LIST_DRAFTS_SUCCESS, LIST_DRAFTS_FAILURE],
    },
  }
}

export function paymentInvoice(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `invoice/${id}/pagoefectivo/`,
      authenticated: true,
      extra: id,
      types: [
        PAYMENT_INVOICE_REQUEST,
        PAYMENT_INVOICE_SUCCESS,
        PAYMENT_INVOICE_FAILURE,
      ],
    },
  }
}

export function deleteDraft(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `drafts/${id}/`,
      authenticated: true,
      extra: id,
      types: [DELETE_DRAFT_REQUEST, DELETE_DRAFT_SUCCESS, DELETE_DRAFT_FAILURE],
    },
  }
}

export function updateOrderFilters(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_ORDER_HISTORICAL_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function updateOrderFiltersPending(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_ORDER_PENDING_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function updateOrderFiltersActive(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_ORDER_ACTIVE_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function updateInvoiceFilters(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_INVOICE_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function updatePurseFilters(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_PURSE_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function selectOrder(obj) {
  return function(dispatch) {
    const action = {
      type: SELECT_ORDER,
      data: obj,
    }
    dispatch(action)
  }
}

export function showModalInfo(body) {
  return function(dispatch) {
    const action = {
      type: SHOW_MODAL_INFO,
      body,
    }
    dispatch(action)
  }
}

export function closeModalInfo() {
  return function(dispatch) {
    const action = {
      type: CLOSE_MODAL_INFO,
    }
    dispatch(action)
  }
}

export function duplicateOrder(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/${id}/duplicate/`,
      authenticated: true,
      types: [
        DUPLICATE_ORDER_REQUEST,
        DUPLICATE_ORDER_SUCCESS,
        DUPLICATE_ORDER_FAILURE,
      ],
    },
  }
}
