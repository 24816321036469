import {
  DESTINATION_ORDER_LIST_REQUEST,
  DESTINATION_ORDER_LIST_SUCCESS,
  DESTINATION_ORDER_LIST_FAILURE,
  UPDATE_ORDER_HISTORICAL_FILTERS,
} from 'actions/dashboardActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
    filters: {
      created_at_0: null,
      created_at_1: null,
      search: '',
    },
    needRefresh: false,
  },
  action,
) {
  switch (action.type) {
    case DESTINATION_ORDER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        errors: {},
      })

    case DESTINATION_ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case DESTINATION_ORDER_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })
    case UPDATE_ORDER_HISTORICAL_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    default:
      return state
  }
}
