import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import confirmMessageReducer from './all/confirmMessageReducer'
import tableSelectReducer from './all/tableSelectReducer'
import regionalizationReducer from './all/regionalizationReducer'
import googleMapsKeyReducer from './all/googleMapsKeyReducer'
import loginReducer from './login/loginReducer'
import paymentReducer from './account/paymentReducer'
import couponReducer from './account/couponReducer'
import pendingOrdersReducer from './dashboard/pendingOrdersReducer'
import activeOrdersReducer, {
  reducerOrderFilter,
} from './dashboard/activeOrdersReducer'
import historicalOrdersReducer from './dashboard/historicalOrdersReducer'
import destinationOrderReducer from './dashboard/destinationOrdersReducer'
import userReducer from './user/userReducer'
import clientPaymentsReducer from './client/clientPaymentReducer'
import orderPriceReducer from './order/orderPriceReducer'
import oldOrderPricesReducer from './order/oldOrderPricesReducer'
import nearbyCouriersReducer from './order/nearbyCouriersReducer'
import vehicleCategoriesReducer from './order/vehicleCategoriesReducer'
import orderCreateReducer from './order/orderCreateReducer'
import trackingReducer from './Tracking/TrackingReducer'
import invoicesListReducer from './dashboard/invoicesListReducer'
import purseMovementsReducer from './dashboard/purseMovementsReducer'
import couponsListReducer from './dashboard/couponsListReducer'
import clientDataReducer from './client/clientDataReducer'
import editProfileReducer from './account/editProfileReducer'
import draftsListReducer from './dashboard/draftsListReducer'
import modalInfoReducer from './dashboard/modalInfoReducer'
import favoriteReducer from './client/favoriteReducer'
import favoriteAddressesReducer from './account/favoriteAddressReducer'
import courierReducer from './courier/courierReducer'
import orderTypesReducer from './global/orderTypesReducer'
import packageTypesReducer from './packageTypes'
import checkDangerCoverageReducer from './order/checkDangerCoverageReducer'

import {
  merchant,
  retrieveMerchant,
  listMerchantType,
  schedule,
  retrieveFacebook,
} from './store/merchantReducer'
import {
  listOrder,
  listOrderHistoric,
  retrieveOrder,
  retrieveOrderDelivery,
  OrderWs,
  listIncomes,
  totalIncomes,
  pendingOrder,
} from './store/historyReducer'
import {
  listProducts,
  importExcelProduct,
  activeProduct,
  deleteProduct,
} from './store/productReducer'
import promotionReducer from './store/promotionReducer'
import guestListReducer from './guest/guestListReducer'

const rootReducer = combineReducers({
  form: formReducer,
  message: confirmMessageReducer,
  tableSelect: tableSelectReducer,
  regionalization: regionalizationReducer,
  googleMapsKey: googleMapsKeyReducer,
  packageTypes: packageTypesReducer,
  global: combineReducers({
    orderTypes: orderTypesReducer,
  }),
  account: combineReducers({
    login: loginReducer,
    profile: clientDataReducer,
    favoriteAddresses: favoriteAddressesReducer,
    register_business: userReducer,
    courier: courierReducer,
  }),
  my_account: combineReducers({
    payment: paymentReducer,
    coupon: couponReducer,
    edit: editProfileReducer,
  }),
  guest: combineReducers({
    list: guestListReducer,
  }),
  dashboard: combineReducers({
    pending: pendingOrdersReducer,
    active: activeOrdersReducer,
    historical: historicalOrdersReducer,
    destination: destinationOrderReducer,
    invoice: invoicesListReducer,
    movements: purseMovementsReducer,
    coupon: couponsListReducer,
    draft: draftsListReducer,
    search: reducerOrderFilter,
    modalInfo: modalInfoReducer,
  }),
  client: combineReducers({
    payment: clientPaymentsReducer,
    favorites: favoriteReducer,
  }),
  order: combineReducers({
    oldPrice: oldOrderPricesReducer,
    price: orderPriceReducer,
    nearbyCouriers: nearbyCouriersReducer,
    vehicleCategories: vehicleCategoriesReducer,
    orderCreate: orderCreateReducer,
    checkDangerCoverage: checkDangerCoverageReducer,
  }),
  tracking: combineReducers({
    orderData: trackingReducer,
  }),
  my_store: combineReducers({
    listProducts,
    importExcelProduct,
    activeProduct,
    deleteProduct,
    merchant,
    retrieveMerchant,
    listMerchantType,
    schedule,
    retrieveFacebook,
    listOrder,
    listOrderHistoric,
    listIncomes,
    totalIncomes,
    pendingOrder,
    retrieveOrder,
    retrieveOrderDelivery,
    OrderWs,
    promotion: promotionReducer,
  }),
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
